import { useRef, useEffect } from "react";

export function useInterval(callback, delay) {
	const savedCallback = useRef();
	const intervalId = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		if (delay !== null) {
			const id = setInterval(tick, delay);
			intervalId.current = id;
			return () => {
				clearInterval(id);
			};
		}
	}, [callback, delay]);

	const resetInterval = () => {
		if (intervalId.current != null) {
			clearInterval(intervalId.current);
		}
		// console.log("resetInterval", intervalId.current);
	};

	return { resetInterval };
}

export default useInterval;
