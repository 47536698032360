import "./App.css";
import { useGoogleLogout, useGoogleLogin } from "react-google-login";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { LogoutIcon, ChipIcon, LoginIcon } from "@heroicons/react/solid";
import useInterval from "./hooks/useInterval";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const CLIENT_ID =
// 	"1036687410039-ne8odm6lpfebqf80q91keng27f496u6u.apps.googleusercontent.com";
// const API_URL = "https://fwy6j5sq3a.execute-api.ap-southeast-1.amazonaws.com/dev";

function App() {
	const [user, setUser] = useState(null);
	const [canAccess, setCanAccess] = useState(false);
	const [isLogin, setIsLogin] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isOperating, setIsOperating] = useState(false);
	const [initial, setInitial] = useState(true);
	const [instanceData, setInstancesData] = useState([]);

	useEffect(() => {
		console.log({ isLogin, isLoading, isOperating, initial });
	}, [isLogin, isLoading, isOperating, initial]);

	const setInstanceState = useCallback(
		async (instanceId, state) => {
			let newState = null;
			try {
				const result = await axios.post(
					"/ec2/set-instance-state",
					{
						idToken: user?.tokenId,
						instanceId,
						state,
					},
					{
						baseURL: process.env.REACT_APP_API_URL,
					}
				);
				// console.log({ result: result.data });
				newState = result.data?.state;
				toast(
					`Action(${state}) ${result.data?.success ? "success" : "failed"}`,
					{
						autoClose: false,
						type: result.data?.success ? "success" : "error",
					}
				);
			} catch (error) {
				toast(`Action(${state}) failed`, {
					autoClose: false,
					type: "error",
				});
				// console.log("setInstanceState error", error);
			}
			return newState ?? null;
		},
		[user?.tokenId]
	);

	const getInstanceState = useCallback(
		async (tokenId) => {
			if (!(user?.tokenId || tokenId)) return [];
			try {
				const result = await axios.post(
					"/ec2/get-instances",
					{
						idToken: tokenId ?? user.tokenId,
					},
					{
						baseURL: process.env.REACT_APP_API_URL,
					}
				);
				return result.data?.instances ?? [];
			} catch (error) {
				return null;
			}
		},
		[user?.tokenId]
	);

	const { resetInterval } = useInterval(async () => {
		if (canAccess) {
			const instancesData = await getInstanceState();
			setInstancesData(instancesData);
			// console.log({ instancesData });
		}
	}, 10000);

	const handleLoginSuccess = async (response) => {
		setIsLoading(true);
		if (response) {
			const { tokenId, profileObj } = response;
			const instancesData = await getInstanceState(tokenId);
			const granted = !!instancesData;

			setUser({ ...profileObj, tokenId });
			setIsLogin(!!tokenId);
			setCanAccess(granted);
			setInstancesData(instancesData);

			toast(granted ? "Access granted" : "Access denied!", {
				type: granted ? "success" : "error",
			});
		}
			setIsLoading(false);
	};

	const handleLogoutSuccess = () => {
		setUser(null);
		setIsLogin(false);
		setCanAccess(false);
		setIsLoading(false);
	};

	const handleFailure = (error) => {
		setIsLoading(false);
		console.log("login failed", error);
	};

	const { signOut } = useGoogleLogout({
		onFailure: handleFailure,
		clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
		onLogoutSuccess: handleLogoutSuccess,
	});

	const { signIn, loaded } = useGoogleLogin({
		clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
		isSignedIn: true,
		onSuccess: handleLoginSuccess,
		onFailure: handleFailure,
	});

	useEffect(() => {
		if (loaded && !isLogin) {
			setInitial(false);
			// setIsLoading(false)
		}
	}, [loaded, isLogin]);

	const handleStopInstance = useCallback(
		async (instanceId) => {
			resetInterval(true);
			setIsOperating(true);
			const newState = await setInstanceState(instanceId, "stop");
			if (newState != null) {
				setInstancesData((prev) => {
					const prevIndex = prev?.findIndex((elem) => elem.id === instanceId);
					if (prevIndex < 0) {
						return prev;
					}
					const newData = [...prev];
					newData[prevIndex].state = newState;
					return newData;
				});
			}
			setIsOperating(false);
		},
		[resetInterval, setInstanceState]
	);

	const handleStartInstance = useCallback(
		async (instanceId) => {
			resetInterval(true);
			setIsOperating(true);
			await setInstanceState(instanceId, "start");
			setIsOperating(false);
		},
		[resetInterval, setInstanceState]
	);

	if (initial) {
		return (
			<div className="bg-black">
				<div className="container mx-auto grid place-content-center h-screen">
					<span className="animate-ping inline-flex h-10 w-10 rounded-full bg-sky-400 opacity-75"></span>
				</div>
			</div>
		);
	}

	if (!isLogin) {
		return (
			<div className="bg-black">
				<div className="container mx-auto grid place-content-center h-screen">
					<div
						className={`w-10 h-10 text-blue-600 font-bold cursor-pointer ${
							isLoading ? "invisible opacity-0" : "visible opacity-100"
						}`}
						onClick={() => {
							setIsLoading(true);
							signIn();
						}}
					>
						<LoginIcon className="rounded outline-dotted outline-offset-4" />
					</div>
					{isLoading && (
						<span className="animate-ping inline-flex h-10 w-10 rounded-full bg-sky-400 opacity-75"></span>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className="h-screen select-none flex flex-col h-screen w-screen justify-between bg-black">
			<header>
				<h1 className="text-3xl font-bold text-white text-center m-5 mt-10">
					Beep Beep Boob!
				</h1>
			</header>
			<main
				className={`container relative mb-auto mx-auto transition-all ${
					isLoading ? "opacity-0 invisible" : "opacity-100 visible"
				}`}
			>
				{isLoading && (
					<div className="z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
						<span className="animate-ping inline-flex h-10 w-10 rounded-full bg-sky-400 opacity-75"></span>
					</div>
				)}

				<div className="flex justify-end">
					<User user={user} canAccess={canAccess} signOut={signOut} />
				</div>
				{canAccess && (
					<div className="bg-slate-800 md:rounded-lg px-6 py-6 ring-1 mt-2 ring-slate-900/5 shadow-xl">
						<div className="flex flex-wrap gap-3 justify-center">
							{instanceData?.map((instance, id) => (
								<Card
									key={id}
									instanceId={instance?.id}
									running={instance?.state === "running"}
									name={instance?.name}
									description={instance?.description}
									dataSource={instance?.dataSource ?? []}
									actionText={instance?.state === "running" ? "Stop" : "Start"}
									onAction={() =>
										instance?.state === "running"
											? handleStopInstance(instance?.id)
											: handleStartInstance(instance?.id)
									}
									// isOperating={isOperating}
									disableAction={
										isOperating ||
										instance?.state === "pending" ||
										instance?.state === "stopping"
									}
								/>
							))}
						</div>
					</div>
				)}
			</main>
			<footer className="">
				<div className="text-center p-6 bg-slate-600">
					<span className="text-slate-200 font-semibold">Beep Beep Boob!</span>
				</div>
			</footer>
			<ToastContainer />
		</div>
	);
}

const User = ({ user, canAccess, signOut }) => (
	<div className="inline-flex items-center justify-center p-2 bg-slate-800 rounded-md shadow-lg">
		{user?.imageUrl && (
			<div className="group flex items-center">
				<img
					className="shrink-0 h-12 w-12 rounded-full"
					src={user?.imageUrl}
					alt="avatar"
				/>
				<div className="mx-2 ltr:ml-3 rtl:mr-3">
					<p className="text-sm font-bold text-slate-200">{user?.name}</p>
					<p className="text-sm font-medium text-slate-300">
						{`${canAccess ? "Access granted" : "Access denied"}`}
					</p>
				</div>
			</div>
		)}
		<button
			onClick={signOut}
			className="h-fit rounded-sm ml-2 p-2 hover:bg-slate-400"
		>
			<LogoutIcon className="h-5 w-5 text-slate-200" />
		</button>
	</div>
);

const Card = ({
	instanceId,
	name,
	actionText,
	description,
	dataSource,
	disableAction,
	onAction,
}) => (
	<div className="max-w-xs border border-gray-400 bg-slate-200 rounded p-4 flex flex-col justify-between leading-normal w-full select-text">
		<div className="mb-8">
			<p className="text-sm text-gray-600 flex items-center">
				<ChipIcon className="h-5 w-5 mr-1" />
				Instance ID {instanceId}
			</p>
			<div className="text-gray-900 font-bold text-xl mb-2">{name}</div>
			<p className="text-gray-700 text-base">{description}</p>
			<div className="h-50 scrollbar scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-200">
				<table className="w-full text-left border-collapse mt-2">
					<tbody className="align-baseline">
						{dataSource?.map((item, index) => {
							return (
								<tr key={index}>
									<td
										translate="no"
										className="pr-2 font-mono font-medium text-xs leading-6 whitespace-nowrap text-blue-600"
									>
										{item?.key}
									</td>
									<td
										translate="no"
										className="pl-2 font-mono text-xs leading-6 text-slate-500"
									>
										{item?.value}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
		<div className="flex items-center">
			<button
				className="bg-slate-900 text-white font-bold py-2 px-4 rounded select-none disabled:cursor-not-allowed disabled:transform-none disabled:transition-none disabled:bg-gray disabled:bg-slate-500 hover:bg-slate-500"
				onClick={onAction}
				disabled={disableAction}
			>
				{actionText}
			</button>
		</div>
	</div>
);

export default App;
